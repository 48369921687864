* {
  font-family: var(--c-lib-tb-font-family);
  margin: 0px;
  padding: 0px;
}

body {
  font-family: 'Roboto', sans-serif;
  background-color: var(--c-lib-tb-grayscaleL400);
  margin: 0;
}

#loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.plus-sign {
  color: #29a13b;
  margin-right: 0.5rem;
  font-weight: normal;
}
.small-text-green,
.text-green {
  color: #4b863a;
}

.small-text {
  font-size: 0.8em;
}
.content-item {
  margin-bottom: 1rem;
  padding: 2rem;
  border-radius: 15px 10px;
  background-color: #00404c;
  color: #fff;
}

.container_blockquote {
  margin-left: 20px;
  padding-left: 10px;
  border-left: 5px solid #ddd;
}

.success-message .asistencia-bolivar {
  display: block;
  color: black;
  margin-top: 1rem;
}
